import { Box } from "@mui/material";

export const ButtonWrapper = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <Box
    sx={{
      width: "32px",
      height: "32px",
      padding: "4px",
      borderRadius: "6px",
      border: "solid 1px #ebebf0",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      ...style,
    }}
  >
    {children}
  </Box>
);
