interface ComponentShieldProps {
  children?: any;
  hasPermission: boolean;
}

export const ComponentShield = ({
  children,
  hasPermission,
}: ComponentShieldProps) => {
  if (hasPermission) {
    return children;
  }
  return <></>;
};
