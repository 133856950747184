import { useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Typography,
  Tooltip as MuiTooltip,
  tooltipClasses,
  capitalize,
} from "@mui/material";
import { Menu, MenuType } from "./Menu";
import { Tooltip } from "./Tooltip";

export interface LocationsProps {
  country_code: string;
  name: string;
}

interface CardPropTypes {
  project_id?: string;
  title: string;
  onClick?: () => void;
  createdAt: string;
  members?: string[];
  membersIds?: string[];
  locations: LocationsProps[];
  participationCount?: number;
  menu: Pick<MenuType, "label" | "icon" | "href" | "onClick" | "hidden">[];
  is_live?: boolean;
}

export const Card = ({
  project_id,
  title,
  createdAt,
  members,
  membersIds,
  locations = [],
  participationCount,
  onClick,
  menu,
  is_live,
}: CardPropTypes) => {
  const hideMenu = menu.every((i) => !i.hidden);
  const [toolTipContent, setToolTipContent] = useState<{
    id: string;
    name: string;
    image: string;
    role: string;
  }>({ id: "", name: "", image: "", role: "" });

  const handleMouseEnter = async (memberId?: string) => {
    try {
      if (!memberId) return;
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_APP_BASE_URL}/organizations/get_users_data?user_id=${memberId}&project_id=${project_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      );
      const data = await response.json();
      setToolTipContent(data);
    } catch (error) {
      console.error(error);
    }
  };

  const formattedLocations = getFormattedNames(
    locations.map((location) => ({
      fullName: location.name,
      shortName: location.country_code,
    })),
    { limit: 5, shortNameLimit: 2 },
  );

  return (
    <Box
      data-testid="card"
      sx={{
        position: "relative",
        border: "1px solid #ebebf0",
        borderRadius: "6px",
        backgroundColor: "#fff",
        display: "flex",
        flex: 1,
        minWidth: "100%",
        minHeight: "165px",
        height: "min-content",
        aspectRatio: 56 / 33,
        px: "24px",
        pb: "24px",
        pt: "32px",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        },
      }}
      onClick={onClick}
    >
      <Box
        data-testid="card-container"
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {is_live && (
          <Box sx={{ margin: "4px" }}>
            <CartStatus />
          </Box>
        )}
        {hideMenu && (
          <Menu
            iconButtonStyles={{ marginLeft: "-4px" }}
            items={menu.filter((i) => !i.hidden).map((m, id) => ({ ...m, id }))}
          />
        )}
      </Box>
      <Stack spacing={1.5} sx={{ maxWidth: "100%" }}>
        <Box>
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1">
            {createdAt} - {participationCount} Participations
          </Typography>
        </Box>
        <Box display="flex">
          <AvatarGroup
            sx={{
              "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 14 },
            }}
            max={5}
          >
            {members?.map((member, index) => (
              <MuiTooltip
                key={index}
                title={`${toolTipContent?.name} (${capitalize(
                  toolTipContent?.role || "",
                )})`}
                enterDelay={600}
                leaveDelay={200}
                PopperProps={{
                  sx: {
                    zIndex: 10001,
                    ['&[data-popper-placement="top"]']: {
                      [`& .${tooltipClasses.arrow}`]: {
                        transform: "rotate(-180deg) !important",
                        left: "calc(50% - 12px) !important",
                      },
                    },
                    [`& .${tooltipClasses.arrow}`]: {
                      backgroundImage:
                        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='20' viewBox='0 0 36 20' fill='none'%3E%3Cpath d='M20.1902 4.81689C27.2303 19.9801 30.4845 20 35.3542 20L0.126789 20C6.63384 20 10.4296 12.9507 13.6831 4.81689C16.9367 -0.605684 16.9367 -0.605684 20.1902 4.81689Z' fill='%231B1D37'/%3E%3C/svg%3E\");",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      width: "16px",
                      height: "10px",
                      "&::before": {
                        display: "none",
                      },
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundImage:
                        "linear-gradient(to bottom, #292c49, #1b1e3d)",
                      padding: "12px",
                    },
                  },
                }}
                placement="top"
                arrow
              >
                <Avatar
                  key={index}
                  sx={{ width: 24, height: 24 }}
                  src={
                    member ||
                    "https://user-images.githubusercontent.com/20648104/199912110-febb71a4-21d0-48d3-8db5-8cf1a512d198.png"
                  }
                  onMouseEnter={() => handleMouseEnter(membersIds?.[index])}
                />
              </MuiTooltip>
            ))}
          </AvatarGroup>
        </Box>
        {/* a */}

        <Stack direction="row" spacing={1}>
          {formattedLocations?.map((location) => location)}
        </Stack>
      </Stack>
    </Box>
  );
};

export const CartStatus = ({ disabled = false }) => (
  <Box
    component="span"
    style={{
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      border: "solid 1px #ebebf0",
      backgroundColor: "#fff",
    }}
  >
    <Box
      component="span"
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        boxShadow: "0 2px 5px 0 #fff",
        ...(!disabled
          ? {
              backgroundImage:
                "linear-gradient(to bottom, #9bd8b8 -11%, #6fb28c)",
            }
          : {
              backgroundImage: "none",
              backgroundColor: "#c6c7d6",
            }),
      }}
    />
  </Box>
);

export function getFormattedNames(
  data: { fullName: string; shortName: string }[],
  { limit = 5, shortNameLimit = 2 },
) {
  if (!data) {
    return [];
  }
  const dataCount = data.length;
  const langLimit = dataCount > limit ? limit : dataCount;
  const buttonStyles = {
    display: "flex",
    width: "auto",
    height: "auto",
    minWidth: "30px",
    cursor: "pointer",
    padding: "3px 6px",
    borderRadius: "6px",
    alignItems: "center",
    backgroundColor: "#fff",
    justifyContent: "center",
    border: "solid 1px #ebebf0",
  };

  if (dataCount <= shortNameLimit) {
    return data.map((item, index) => (
      <Box key={index} sx={buttonStyles}>
        {item.fullName}
      </Box>
    ));
  }

  const shortLocations = data.slice(0, langLimit).map((item, index) => (
    <Tooltip
      content={
        <Typography variant="body1" color="white">
          {item.fullName}
        </Typography>
      }
    >
      <Box key={index} sx={buttonStyles}>
        {item.shortName.toUpperCase()}
      </Box>
    </Tooltip>
  ));

  if (dataCount > limit) {
    shortLocations.push(
      <Tooltip
        tooltipProps={{
          placement: "bottom",
          PopperProps: { sx: { "> div": { maxWidth: "fit-content" } } },
        }}
        content={
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "9px 13px",
              maxWidth: "380px",
            }}
          >
            {data.slice(limit).map((item) => (
              <Typography variant="body1" color="white">
                {item.fullName}
              </Typography>
            ))}
          </Box>
        }
      >
        <Box sx={buttonStyles}>+{dataCount - limit}</Box>
      </Tooltip>,
    );
  }

  return shortLocations;
}
