export const calendarStyles = {
  borderTop: "none",
  background: "#fff",
  ".my_custom_calendar": {
    padding: 0,
    width: "100%",
    minHeight: "auto",
    maxHeight: "35em",
    borderRadius: 0,
  },

  ".Calendar": {
    // Calendar__header - Month and Year Selector and Arrows to change month
    "&__header": {
      borderBottom: "1px solid #f2f2f5",
      padding: "0",
      "> button:first-of-type": { order: 2, borderTop: "1px solid #f2f2f5" },
      "> button:nth-of-type(2)": {
        order: 3,
        borderBottom: "1px solid #f2f2f5",
      },
      ".Calendar__monthArrowWrapper": {
        opacity: 1,
        padding: "7px",
        "> span": {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cmask id='a' width='24' height='24' x='0' y='0' maskUnits='userSpaceOnUse' style='mask-type:luminance'%3E%3Cpath fill='%231B1E3D' d='M24 24H0V0h24v24Z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%231B1E3D' fill-rule='evenodd' d='m12.39 9.988 2.96 3.7a.5.5 0 0 1-.39.812H9.04a.5.5 0 0 1-.39-.812l2.96-3.7a.5.5 0 0 1 .78 0Z' clip-rule='evenodd'/%3E%3C/g%3E%3Cpath fill='%231B1E3D' fill-rule='evenodd' d='m12.39 9.988 2.96 3.7a.5.5 0 0 1-.39.812H9.04a.5.5 0 0 1-.39-.812l2.96-3.7a.5.5 0 0 1 .78 0Z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
          backgroundSize: "cover",
          width: "24px",
          height: "24px",
        },
      },
      ".Calendar__monthYearContainer": {
        margin: "12px 0px",
        ".Calendar__monthYear": {
          borderRight: "1px solid #f2f2f5",
          width: "100%",
          paddingLeft: "14px",
          "> *": {
            padding: "0.2em 1px",
          },
          "&::before": {
            content: "''",
            display: "block",
            height: "24px",
            width: "24px",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h24v24H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%231B1E3D' d='m12.39 9.988 2.96 3.7a.5.5 0 0 1-.39.812H9.04a.5.5 0 0 1-.39-.812l2.96-3.7a.5.5 0 0 1 .78 0z' mask='url(%23b)' transform='rotate(-180 12 12)'/%3E%3Cpath fill='%231B1E3D' d='m12.39 9.988 2.96 3.7a.5.5 0 0 1-.39.812H9.04a.5.5 0 0 1-.39-.812l2.96-3.7a.5.5 0 0 1 .78 0z' mask='url(%23b)' transform='rotate(-180 12 12)'/%3E%3C/g%3E%3C/svg%3E")`,
            position: "absolute",
            transform: "translate(0px, -50%)",
            top: "50%",
            backgroundSize: "cover",
          },
          ".Calendar__monthText": {
            marginLeft: "24px",
            opacity: 1,
            transform: "none !important",
            "&:hover": { background: "transparent" },
          },
          ".Calendar__yearText": {
            transform: "none !important",
            opacity: 1,
            "&:hover": { background: "transparent" },
          },
        },
      },
    },

    // Calendar__yearSelectorAnimationWrapper - Year Selector Animation Wrapper
    ".Calendar__yearSelectorAnimationWrapper": {
      height: "89%",
      ".Calendar__yearSelectorWrapper": {
        "&::before, &::after": {
          display: "none",
        },
        ".Calendar__yearSelector": {
          padding: "8px",

          width: "76.5%",
          borderRight: "1px solid #f2f2f5",
          ".Calendar__yearSelectorItem": {
            // Year Item
            width: "100%",
            marginTop: "8px",
            "> button": {
              width: "100%",
              padding: "7.7px 8px",
              borderRadius: "6px",
              textAlign: "start",
              "&:hover": {
                backgroundImage:
                  "linear-gradient(to bottom, #f2f2f5, #eaeaf3) !important",
              },
            },
            "&.-active": {
              button: {
                backgroundImage: "linear-gradient(to bottom, #f2f2f5, #eaeaf3)",
                color: "#1B1E3D",
              },
            },
          },
        },
      },
    },

    // Calendar__monthSelectorAnimationWrapper - Month Selector Animation Wrapper
    ".Calendar__monthSelectorAnimationWrapper": {
      height: "89%",
      ".Calendar__monthSelector": {
        padding: 0,
        ".Calendar__monthSelectorItem": {
          fontSize: "11px",
          "&.-active": {
            button: {
              color: "#1B1E3D",
              backgroundImage: "linear-gradient(to bottom, #f2f2f5, #eaeaf3)",
              borderRadius: "6px",
            },
          },
        },
      },
    },

    // Calendar__weekDays Sun Mon Tue Wed Thu Fri Sat
    "&__weekDays": {
      padding: "22px 20px 0 20px",
    },
    // Calendar__weekDay Sun or Mon or Tue or Wed or Thu or Fri or Sat
    "&__weekDay": {
      fontSize: "14px",
      color: "#8D8F9E",
    },

    // Calendar__section 1 2 3 4 5 6 7 8 9 10 11 12 ... 30/31
    "&__section": {
      padding: "0 20px",
    },
    // Calendar__day 1 or 2 or 3 or 4 or 5 or 6 or 7 or 8 or 9 or 10 or 11 or 12 ... 30/31
    "&__day": {
      "&:hover": {
        background: "transparent!important",
        "&::before": {
          content: "''",
          display: "block",
          height: "34px",
          width: "34px",
          position: "absolute",
          borderRadius: "50%",
          background: "#f2f2f5",
          zIndex: -1,
        },
      },
      "&.-selected": {
        position: "relative",
        background: "transparent",
        "&::before": {
          content: "''",
          display: "block",
          height: "34px",
          width: "34px",
          position: "absolute",
          borderRadius: "50%",
          backgroundImage: "linear-gradient(to bottom, #9bd8b8, #6fb28c)",
          zIndex: -1,
        },
      },
    },
  },
};
