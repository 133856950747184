import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React, { FC } from "react";

export interface AccountMenuOptions {
  label?: string;
  icon?: string;
  href?: string;
  disabled?: boolean;
  iconProps?: any;
  handler?: string;
  sx?: { [key: string]: string };
}

export interface AccountMenuProps {
  user: {
    name?: string;
    avatar?: string;
  };
  handlers?: {
    [key: string]: () => void;
  };
  accountMenuOptions: AccountMenuOptions[];
}

export interface IconContainerProps extends BoxProps {
  solidBackground?: boolean;
}

export const AccountMenu: FC<AccountMenuProps> = ({
  user,
  accountMenuOptions = [],
  handlers = {},
}) => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2, p: 0 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          src={
            user?.avatar ||
            "https://user-images.githubusercontent.com/20648104/199912110-febb71a4-21d0-48d3-8db5-8cf1a512d198.png"
          }
          sx={{
            width: "28px",
            height: "28px",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            top: "0 !important",
            right: "0 !important",
            left: "auto !important",
            overflow: "hidden",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 0,
            width: 200,
            "& .MuiList-root": {
              py: 0,
            },
            "& .MuiDivider-root": {
              my: "0px !important",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ py: 0, height: 48, borderBottom: "1px solid #ebebf0" }}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minHeight={48}
          >
            <Typography
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="body1"
            >
              {user?.name}
            </Typography>
            <Avatar
              src={
                user?.avatar ||
                "https://user-images.githubusercontent.com/20648104/199912110-febb71a4-21d0-48d3-8db5-8cf1a512d198.png"
              }
              sx={{ width: "28px", height: "28px" }}
            />
          </Box>
        </MenuItem>

        {accountMenuOptions.map((option, key) => (
          <Box key={key}>
            <MenuItem
              sx={{ height: 40 }}
              disabled={option.disabled}
              onClick={() => {
                option?.href
                  ? router.push(option?.href)
                  : option?.handler && handlers?.[option.handler]?.();
              }}
            >
              {option?.icon && (
                <ListItemIcon>
                  <IconContainer {...option.iconProps}>
                    <Image
                      src={option?.icon}
                      height={24}
                      width={24}
                      alt={option?.label || "icon"}
                    />
                  </IconContainer>
                </ListItemIcon>
              )}
              <ListItemText
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {option?.label}
              </ListItemText>
            </MenuItem>
            {key !== accountMenuOptions.length - 1 && <Divider />}
          </Box>
        ))}
      </Menu>
    </>
  );
};

const IconContainer = styled(Box)<IconContainerProps>`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-image: ${(props) =>
    props?.solidBackground &&
    "linear-gradient(to bottom, #f8f8fa 0%, #ebebf3)"};
  // background-color: ${(props) =>
    props?.solidBackground && "rgba(0,0,0,0.1)"};
  border: ${(props) => !props?.solidBackground && "1px solid #e0e0e0"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
