import { UniqueIdentifier } from "@dnd-kit/core";
import styled from "@emotion/styled";
import { Box, SxProps } from "@mui/material";
import classNames from "classnames";
import React, { forwardRef, HTMLAttributes, ReactElement } from "react";
import { SelectArrowIcon } from "ui";

import { Handle } from "./Handle";
import { Remove } from "./Remove";

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  id: UniqueIdentifier;
  selectedId?: UniqueIdentifier;
  childCount?: number;
  clone?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
  isOver?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  withHandle?: boolean;
  handler?: ReactElement;
  indentationWidth: number;
  value: string;
  contentElement?: ReactElement;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  itemContainerStyles?: React.CSSProperties;
  itemStyles?: React.CSSProperties;
  selectedItemStyles?: React.CSSProperties;
  itemsInsideContainerStyles?: React.CSSProperties;
  itemContentStyles?: SxProps;
  parentId?: UniqueIdentifier | null;
  isContainer?: boolean;
  index?: number;
  orgDepth?: boolean;
}

// eslint-disable-next-line react/display-name
export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      withHandle,
      handler,
      collapsed: collapsedProp,
      isOver,
      onCollapse,
      onRemove,
      style,
      wrapperRef,
      contentElement,
      itemContainerStyles,
      itemStyles,
      selectedId,
      selectedItemStyles,
      itemsInsideContainerStyles,
      itemContentStyles,
      parentId,
      isContainer,
      index,
      orgDepth,
      disabled,
      ...props
    },
    ref,
  ) => {
    const collapsed = isOver ? true : collapsedProp;

    React.useEffect(() => {
      if (isOver) {
        onCollapse?.();
      }
    }, [isOver, onCollapse]);

    return (
      <Wrapper
        className={classNames(
          clone && "clone",
          ghost && "ghost",
          indicator && "indicator",
          disableSelection && "disableSelection",
          disableInteraction && "disableInteraction",
        )}
        ref={wrapperRef}
        style={
          {
            opacity: disabled ? 0.5 : 1,
            "--spacing": orgDepth && `${indentationWidth * depth}px`,
            width: "100%",
            marginBottom: "0",
            ...itemContainerStyles,
            ...itemStyles,
            ...(selectedId === id ? selectedItemStyles : {}),
            ...((parentId && !isContainer && itemsInsideContainerStyles) || {}),
            position: "relative",
            zIndex: ghost ? 1000 : index,
          } as React.CSSProperties
        }
        {...props}
      >
        <STreeItem
          className="TreeItem"
          ref={ref}
          sx={{ ...style, ...itemContentStyles }}
        >
          <Box
            sx={{
              borderLeft: depth > 0 && orgDepth ? "1px solid #ebebf0" : "none",
            }}
          >
            {withHandle &&
              (handler ? (
                <Box sx={{ alignSelf: "stretch" }} {...handleProps}>
                  {handler}
                </Box>
              ) : (
                <Handle {...handleProps} />
              ))}
            {onCollapse && (
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  transform: collapsed ? "rotate(-90deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                  ":hover": {
                    background: "#0000000a",
                  },
                }}
                onClick={onCollapse}
              >
                <SelectArrowIcon />
              </Box>
            )}
            <Box flex={1} {...(!withHandle ? handleProps : {})}>
              {contentElement}
            </Box>
            {!clone && onRemove && <Remove onClick={onRemove} />}
            {clone && childCount && childCount > 1 ? (
              <span className="count">{childCount}</span>
            ) : null}
          </Box>
        </STreeItem>
      </Wrapper>
    );
  },
);

const Wrapper = styled.li`
  list-style: none;
  box-sizing: border-box;
  background-color: #fcfcfd !important;

  margin-bottom: -1px;
  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;

    .TreeItem {
      --vertical-padding: 5px;

      padding-right: 24px;
      border-radius: 4px;
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0;
        height: 1px;
        border-color: #2389ff;
        background-color: #56a1f8;
        margin-left: var(--spacing);

        &:before {
          position: absolute;
          left: -8px;
          top: -5px;
          display: block;
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #2389ff;
          background-color: #2389ff;
        }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }
    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem > * {
      box-shadow: none;
      background-color: transparent;
    }
  }

  .disableInteraction {
    pointer-events: none;
  }

  .disableSelection,
  .clone {
    .Text,
    .Count {
      user-select: none;
      -webkit-user-select: none;
    }
  }
`;

const STreeItem = styled(Box)`
  --vertical-padding: 10px;

  position: relative;
  box-sizing: border-box;
  padding-left: var(--spacing);
  > div {
    display: flex;
    align-items: center;
    background-color: #fff;
  }

  .text {
    flex-grow: 1;
    padding-left: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .count {
    position: absolute;
    top: -10px;
    right: -0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2389ff;
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff;
  }
`;
