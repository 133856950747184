import {
  Announcements,
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  Modifier,
  Modifiers,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { CSS } from "@dnd-kit/utilities";
import { SxProps } from "@mui/material";
import debounce from "lodash.debounce";
import { SortableTreeItem } from "./components";
import type {
  DragDropItem,
  DragDropItems,
  FlattenedItem,
  SensorContext,
} from "./types";
import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from "./utilities";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  disabled?: boolean;
  collapsible?: boolean;
  defaultItems: DragDropItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  withHandle?: boolean;
  handler?: ReactElement;
  selectedId?: UniqueIdentifier;
  itemStyles?: React.CSSProperties;
  selectedItemStyles?: React.CSSProperties;
  itemContainerStyles?: React.CSSProperties;
  itemsInsideContainerStyles?: React.CSSProperties;
  itemContentStyles?: SxProps;
  DragPreviewStyles?: React.CSSProperties;
  modifiers?: Modifiers;
  onDragEnd?: (items: DragDropItem[]) => void;
}

export const DragDrop = ({
  disabled = false,
  collapsible = true,
  defaultItems,
  indicator = false,
  indentationWidth = 24,
  removable,
  itemContainerStyles = {},
  itemsInsideContainerStyles = {},
  itemStyles = {},
  withHandle,
  selectedId,
  selectedItemStyles = {},
  itemContentStyles = {},
  DragPreviewStyles = {},
  modifiers,
  handler,
  onDragEnd,
}: Props) => {
  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [activeOverElement, setActiveOverElement] = useState(null);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const enhanceItems = (defaultItems: DragDropItems, items: DragDropItems) => {
    // Flatten the tree structure of the given items
    const flattenItems = flattenTree(items);

    // Recursively map over default items and children
    // and set collapsed to the corresponding value in items
    const enhanceTree: (tree: DragDropItems) => DragDropItems = (tree) => {
      return tree.map((item) => {
        const flattenItem = flattenItems.find((i) => i.id === item.id);

        if (flattenItem) {
          return {
            ...item,
            // Use the collapsed value from the flattened item
            collapsed: flattenItem.collapsed,
            children: item.children ? enhanceTree(item.children) : [],
          };
        }
        return item;
      });
    };

    return enhanceTree(defaultItems);
  };

  useEffect(() => {
    setItems(enhanceItems(defaultItems, items));
  }, [defaultItems]);

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: unknown, value: unknown) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const itemsWithIndex = flattenedTree.map((item, index) => ({
      ...item,
      index: flattenedTree.length - index,
    }));
    const collapsedItems = itemsWithIndex.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children?.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      itemsWithIndex,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, items]);

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
        )
      : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier,
  ) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items), getCircularReplacer()),
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem?.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);
    const activeItem = flattenedItems.find(({ id }) => id === activeId);
    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }
    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    const clonedItems: FlattenedItem[] = JSON.parse(
      JSON.stringify(flattenTree(items), getCircularReplacer()),
    );

    const overIndex = clonedItems.findIndex(({ id }) => id === over?.id);
    const overItem = clonedItems[overIndex];

    const debounced = debounce((overt) => {
      if (!overt || (overItem && overt !== activeOverElement)) {
        setActiveOverElement(overItem.container ? overt : null);
      }
    }, 500);
    debounced(over?.id);

    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = flattenTree(items);
      const parent = flattenedItems.find(({ id }) => id === parentId);
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      const isContainer = !!parent?.container;
      const rightDept = isContainer ? depth : activeTreeItem.depth;

      clonedItems[activeIndex] = {
        ...activeTreeItem,
        depth: rightDept,
        parentId: isContainer ? parentId : null,
      };
      const sort = (array: any[], oldIndex: number, newIndex: number) => {
        if (newIndex >= array.length) {
          var k = newIndex - array.length;
          while (k-- + 1) {
            array.push(undefined);
          }
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        return array;
      };

      const sortedItems = sort(clonedItems, activeIndex, overIndex);

      const newItems = buildTree(sortedItems);

      type DataType = { id: string; children: DataType[] };
      const getData: (item: DragDropItems) => DataType[] = (items) =>
        items.map((item) => ({
          id: item.id,
          children: item.children ? getData(item.children) : [],
          //   collapsed: item.collapsed,
        }));
      onDragEnd?.(getData(newItems));

      setItems(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems((items) => removeItem(items, id));
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) => {
      const result = setProperty(items, id, "collapsed", (value) => {
        return !value;
      });
      type DataType = { id: string; children: DataType[] };
      const getData: (item: DragDropItems) => DataType[] = (items) =>
        items.map((item) => ({
          id: item.id,
          children: item.children ? getData(item.children) : [],
          collapsed: item.collapsed,
        }));
      onDragEnd?.(getData(result));
      return result;
    });
  }

  const adjustTranslate: Modifier = ({ transform }) => {
    return {
      ...transform,
      y: transform.y - 25,
    };
  };

  useEffect(() => {
    // Clone the items array
    const clonedItems: FlattenedItem[] = flattenTree(items);

    // Find the index of the element with an id matching activeOverElement
    const overIndex = clonedItems.findIndex(
      ({ id }) => id === activeOverElement,
    );

    // If an element was found, and it is not already collapsed,
    // and its id does not match the activeId (the element being dragged)
    if (
      overIndex > -1 &&
      !clonedItems[overIndex].collapsed &&
      overIndex !== activeId
    ) {
      // Set its collapsed property to false
      clonedItems[overIndex].collapsed = false;

      // Build a new tree-like structure using the modified array
      const newItems = buildTree(clonedItems);

      // Update the component's items state with the new structure
      setItems(newItems);
    }
  }, [activeOverElement]);

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(
          ({
            id,
            children,
            collapsed,
            depth,
            content,
            parentId,
            container,
            index,
          }) => {
            // Find the parent item of the current item
            const parent = flattenedItems.find(
              ({ id }) => id === projected?.parentId,
            );

            // Find the currently selected item that is being dragged
            const selectedItem = flattenedItems.find(
              ({ id }) => id === activeId,
            );

            // Check if the parent item is a container
            const isParentContainer = parent?.container || undefined;

            // Calculate the depth of the current item based on the projected depth and whether the parent is a container
            const orgDepth =
              id === activeId && projected ? projected.depth : depth;

            // Check if the current item is the selected item and if the parent is a container
            const condition =
              selectedItem?.id === id && parent && !isParentContainer;

            // Calculate the final depth based on the previous condition and the selected item's depth
            const rightDepth = condition ? selectedItem?.depth : orgDepth;

            return (
              <SortableTreeItem
                id={id}
                key={id}
                value={id}
                contentElement={content}
                disabled={disabled}
                depth={rightDepth}
                orgDepth={!!orgDepth}
                indicator={indicator}
                itemContainerStyles={itemContainerStyles}
                itemsInsideContainerStyles={itemsInsideContainerStyles}
                itemContentStyles={itemContentStyles}
                itemStyles={itemStyles}
                selectedItemStyles={selectedItemStyles}
                withHandle={withHandle}
                isContainer={container}
                handler={handler}
                selectedId={selectedId}
                indentationWidth={indentationWidth}
                collapsed={Boolean(collapsed && children?.length)}
                parentId={parentId}
                index={index}
                onCollapse={
                  collapsible && children?.length
                    ? () => handleCollapse(id)
                    : undefined
                }
                onRemove={removable ? () => handleRemove(id) : undefined}
              />
            );
          },
        )}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={modifiers || (indicator ? [adjustTranslate] : undefined)}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                clone
                id={activeId}
                disabled={disabled}
                depth={activeItem.depth}
                value={activeId.toString()}
                contentElement={activeItem.content}
                indentationWidth={indentationWidth}
                childCount={getChildCount(items, activeId) + 1}
                withHandle={withHandle}
                handler={handler}
                style={DragPreviewStyles}
                itemContainerStyles={itemContainerStyles}
                itemsInsideContainerStyles={itemsInsideContainerStyles}
                itemContentStyles={itemContentStyles}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};

export * from "./SortableGrid";
export * from "./Basic";
