import {
  Modal,
  Box,
  Typography,
  Button,
  Divider,
  SxProps,
  Theme,
} from "@mui/material";
import { styled } from "@mui/system";

type ConfirmationModalProps = {
  sx?: SxProps<Theme>;
  open: boolean;
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: string;
  cancelText: string;
  children?: React.ReactNode;
  disableConfirm?: boolean;
  disableCancel?: boolean;
};

const styles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "common.white",
  borderRadius: "3px",
  boxShadow: "0 20px 100px 0 rgba(33, 41, 61, 0.11)",
};

const buttonHoverStyles = {
  "&:hover": {
    backgroundColor: "common.white",
    color: "black",
    border: "1px solid #bbbbbb",
  },
};

export const ConfirmationModal = ({
  sx,
  open,
  title,
  description,
  onConfirm,
  confirmText,
  cancelText,
  onCancel,
  children,
  disableConfirm,
  disableCancel,
}: ConfirmationModalProps) => (
  <Modal
    open={open}
    onClose={onCancel}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{ ...styles, ...sx }}>
      <Box p={5}>
        <Typography id="modal-modal-title" variant="h5">
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="subtitle1"
          sx={{ mt: 1 }}
        >
          {description}
        </Typography>
        {children && <Box mt={2}>{children}</Box>}
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        p={1.5}
      >
        <ModalButton
          style={{ marginRight: "0.5rem" }}
          disabled={disableCancel}
          sx={buttonHoverStyles}
          onClick={onCancel}
          variant="outlined"
        >
          {cancelText}
        </ModalButton>
        <ModalButton
          sx={buttonHoverStyles}
          disabled={disableConfirm}
          onClick={onConfirm}
          variant="outlined"
        >
          {confirmText}
        </ModalButton>
      </Box>
    </Box>
  </Modal>
);

const ModalButton = styled(Button)`
  width: 80px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 6px;
  text-transform: none;
  border: ${(props) => !props?.disabled && "1px solid #e0e0e0"};
  color: ${(props) => (props?.disabled ? "#bbbbbb" : "black")};
`;
