import {
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { SelectArrowIcon } from "./Icons";

interface OptionProps {
  label: string;
  id: string;
}

interface MultiSelectProps {
  defaultValue?: string[];
  options: OptionProps[];
  onSelect?: (value: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
  requireMin?: number;
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1.5px #B3B6C2, inset 0 -1px 0 rgba(16,22,26,.1)",
  ".Mui-focusVisible &": {
    outline: "2px auto #6270c1",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    content: '""',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fillRule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23b2b8d5'/%3E%3C/svg%3E\")",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#6270c1",
  boxShadow: "none",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fillRule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const CustomPaper = (props: any) => {
  return (
    <>
      <Paper
        style={{
          border: "1px solid #ebebf0",
          borderTop: "none",
          borderBottom: "none",
        }}
        {...props}
      />
    </>
  );
};

export const MultiSelect: FC<MultiSelectProps> = ({
  defaultValue = [],
  options,
  onSelect,
  disabled = false,
  placeholder = "Select",
  requireMin = 0,
}) => {
  const [value, setValue] = useState<OptionProps[]>([]);

  const handleSelect = (_: any, newValue: OptionProps[]) => {
    if (newValue.length >= requireMin) {
      setValue([...newValue]);
      onSelect?.(newValue.map((item) => item.id));
    }
  };

  useEffect(() => {
    if (defaultValue.length) {
      const selected = defaultValue?.map((item) => {
        const option = options?.find((option) => option.id === item);
        return option || { id: "", label: "" };
      });

      setValue(selected);
    }
  }, [defaultValue, options]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Box
      sx={{
        ".MuiAutocomplete-root": {
          border: "none !important",
        },
        ".MuiInputBase-root": {
          border: "1px solid #ebebf0",
          borderColor: "#ebebf0 !important",
          borderWidth: "1px !important",
          p: 0,
          minHeight: 40,
          backgroundColor: "#fff!important",
          padding: "0 2px!important",
          zIndex: isMenuOpen ? 2 : 1,
        },
      }}
    >
      {isMenuOpen && (
        <Backdrop
          sx={{
            zIndex: 1,
            color: "#fff",
            backgroundColor: "rgba(41, 44, 87, 0.2)",
          }}
          open={isMenuOpen}
        />
      )}
      <Autocomplete
        disableClearable={requireMin > 0}
        PaperComponent={CustomPaper}
        disabled={disabled}
        multiple
        aria-sort="none"
        value={value}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        disableCloseOnSelect
        onChange={handleSelect}
        options={options}
        getOptionLabel={(option) => option.label}
        autoComplete={false}
        popupIcon={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 24,
              height: 24,
            }}
          >
            <SelectArrowIcon color="#1B1E3D" w={8} h={8} />
          </Box>
        }
        ChipProps={{
          deleteIcon: <></>,
          style: {
            borderRadius: 3,
            backgroundColor: "#f4f6ff",
            border: "solid 1px rgba(98, 112, 193, 0.3)",
            color: "#3c53ae",
          },
          sx: {
            mx: "2px!important",
          },
        }}
        renderOption={(props, option, { selected }) => (
          <li
            style={{
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 0,
              paddingTop: 0,
            }}
            {...props}
          >
            <Checkbox
              disableRipple
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              inputProps={{ "aria-label": "Checkbox demo" }}
              size="small"
              style={{ paddingLeft: 4, paddingRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={!value?.length ? placeholder : ""}
          />
        )}
      />
    </Box>
  );
};
