import {
  Box,
  Checkbox,
  ListItemIcon,
  MenuItem,
  Select as SelectComponent,
} from "@mui/material";
import { ReactElement, forwardRef, useImperativeHandle, useState } from "react";

interface OptionValue {
  id: any;
  label: string;
  value?: string | ReactElement;
  rightIcon?: React.ElementType;
}

interface SelectProps {
  ref?: any;
  disabled?: boolean;
  defaultValue?: string | number;
  withCheckbox?: boolean;
  checkboxIcon?: ReactElement;
  options?: OptionValue[];
  placeholder?: string;
  componentIcon?: (open: boolean) => ReactElement;
  onSelect?: (value: any) => void;
  buttonProps?: {
    [key: string]: any;
  };
  listProps?: {
    [key: string]: any;
  };
  listItemProps?: {
    [key: string]: any;
  };
  boxProps?: {
    [key: string]: any;
  };
  elevateTriggerButton?: boolean;
}

export const Select: React.FC<SelectProps> = forwardRef(function Select(
  {
    defaultValue,
    withCheckbox,
    checkboxIcon,
    options,
    componentIcon,
    onSelect,
    buttonProps,
    listProps,
    listItemProps,
    boxProps,
    placeholder = "",
    elevateTriggerButton = false,
  },
  ref: any,
) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selected, setSelected] = useState<OptionValue | undefined>(
    options?.find((opt) => opt.id === defaultValue),
  );

  const handleChange = (id: string | number) => {
    setSelected(options?.find((opt) => opt.id === id));
    onSelect?.(id);
  };

  useImperativeHandle(ref, () => ({
    resetRole() {
      setSelected(options?.find((opt) => opt.id === defaultValue));
    }
  }));

  return (
    <Box
      sx={{
        ".MuiInputBase-root": {
          border: "none !important",
          position: "relative",
          zIndex: isMenuOpen && elevateTriggerButton ? 1301 : 1,
          ...(isMenuOpen &&
            elevateTriggerButton && {
              overflow: "hidden",
              borderBottomLeftRadius: "0px!important",
              borderBottomRightRadius: "0px!important",
            }),
          ...(buttonProps?.styles || {}),
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid #ebebf0",
          borderColor: "#ebebf0 !important",
          borderWidth: "1px !important",
        },
        ...(boxProps?.styles || {}),
      }}
    >
      <SelectComponent
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        sx={{
          ".MuiSelect-select": {
            paddingTop: "5.9px",
            paddingBottom: "5.9px",
            width: "auto",
            minWidth: "min-content",
            maxWidth: "max-content",
          },
        }}
        labelId="mutiple-select-label"
        id="demo-simple-select"
        value={selected?.label}
        onChange={(e: any) => handleChange(e.target.value)}
        displayEmpty={!!placeholder}
        renderValue={() => selected?.label || placeholder}
        {...(componentIcon && {
          IconComponent: (props) => (
            <Box
              sx={{
                position: "absolute",
                right: "4px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            >
              {componentIcon?.(props.className.includes("iconOpen"))}
            </Box>
          ),
        })}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
            ...(listProps?.anchorOrigin || {}),
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
            ...(listProps?.transformOrigin || {}),
          },
          sx: {
            ".MuiPaper-root": {
              borderRadius: "6px",
              ...(isMenuOpen &&
                elevateTriggerButton && {
                  borderTopRightRadius: "0px!important",
                }),
            },
            ".MuiList-root": {
              padding: listProps?.styles?.padding || "0",
              ...(listProps?.styles || {}),
              ".MuiMenuItem-root": {
                padding: "16px 8px 16px 16px",
                // background: "white",
                ".MuiTouchRipple-root": {
                  display: "none",
                },
              },
              ".MuiTypography-subtitle2": {
                margin: 0,
              },
            },
          },
        }}
      >
        {options?.map(
          ({ id, label, value, rightIcon: RightIcon }: OptionValue) => (
            <MenuItem
              key={id}
              value={id}
              sx={{
                justifyContent: "space-between",
                width: listItemProps?.styles?.width || "300px",
                ...(listItemProps?.styles || {}),
              }}
            >
              {value || label}
              <ListItemIcon
                sx={{
                  ...(RightIcon && {
                    justifyContent: "flex-end",
                  }),
                  ".MuiCheckbox-root:hover": {
                    background: "transparent",
                  },
                }}
              >
                {withCheckbox && (
                  <Checkbox
                    checked={selected?.id === id}
                    checkedIcon={checkboxIcon}
                    {...(checkboxIcon && {
                      icon: <div style={{ display: "none" }}></div>,
                    })}
                  />
                )}
                {RightIcon && <RightIcon />}
              </ListItemIcon>
            </MenuItem>
          ),
        )}
      </SelectComponent>
    </Box>
  );
});
