"use client";

import React, { useState, ReactNode } from "react";
import { Box, SxProps, Theme } from "@mui/material";

import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { GripIcon } from "../../Icons";

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  itemStyle,
  handleComponent,
  useHandle,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    ...itemStyle,
    opacity: isDragging ? 0.5 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: useHandle ? "default" : "grab",
  };

  const handleProps = useHandle ? { ...attributes, ...listeners } : {};

  return (
    <Box ref={setNodeRef} sx={style}>
      {useHandle && (
        <div
          className="drag-item-handle"
          style={{ cursor: "grab", display: "flex" }}
          {...handleProps}
        >
          {handleComponent || <GripIcon />}
        </div>
      )}
      <div
        className="drag-item-content-wrapper"
        {...(useHandle ? {} : { ...attributes, ...listeners })}
      >
        {item.content}
      </div>
    </Box>
  );
};

export const DraggableList: React.FC<DraggableListProps> = ({
  items,
  onReorder,
  itemStyle,
  listStyle,
  handleComponent,
  useHandle = false,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      onReorder(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Box sx={listStyle}>
          {items.map((item) => (
            <DraggableItem
              key={item.id}
              item={item}
              itemStyle={itemStyle}
              handleComponent={handleComponent}
              useHandle={useHandle}
            />
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
};

// Types
export interface DragItem {
  id: string;
  content: ReactNode;
}

interface DraggableItemProps {
  item: DragItem;
  itemStyle?: SxProps<Theme>;
  handleComponent?: ReactNode;
  useHandle?: boolean;
}

interface DraggableListProps {
  items: DragItem[];
  onReorder: (newItems: DragItem[]) => void;
  itemStyle?: SxProps<Theme>;
  listStyle?: SxProps<Theme>;
  handleComponent?: ReactNode;
  useHandle?: boolean;
}

// Examples
//   const [items, setItems] = useState<Item[]>([
//     { id: "1", content: "Item 1" },
//     { id: "2", content: "Item 2" },
//     { id: "3", content: "Item 3" },
//   ]);

//   const handleReorder = (newItems: Item[]) => {
//     setItems(newItems);
//   };

//   return <DraggableList items={items} onReorder={handleReorder} />
