import { styled } from "@mui/system";
import { IoMdCheckmark } from "react-icons/io";

const Checkbox = styled("div")({
  width: "24px",
  height: "24px",
  margin: "0 6px 0 0",
  borderRadius: "3px",
  border: "solid 1px #ebebf0",
  backgroundColor: "#fff",
});

const CheckboxChecked = styled(Checkbox)({
  border: "solid 1px #6270c1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CheckboxIcon = () => {
  return <Checkbox role="checkbox" />;
};

export const CheckboxIconChecked = () => {
  return (
    <CheckboxChecked role="checkbox">
      <IoMdCheckmark size={18} color="#6270c1" />
    </CheckboxChecked>
  );
};
